<template>
  <div class="px-0 container-fluid h-100" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="mb-4 row w-100">
            <div class="px-0 pl-3 mb-0 text-left col-12">
              <BreadCrumbComponent
                :pageTitles="['Course Work set', courseWorkTitle || '...']"
              />
            </div>
          </div>

          <div class="row w-100">
            <div class="text-left col-12">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Course Work</strong>
                <div style="font-size: 15px; color: var(--el-app-primary)">
                  {{ courseWorkTitle || "..." }}
                </div>
              </div>
            </div>
          </div>
          <div class="search_add_section">
            <div class="">
              <input
                type="text"
                @input="onSearch"
                v-model.trim="filters[0].value"
                placeholder="Search by Title"
                class="search_by_input"
                spellcheck="off"
              />
            </div>
            <div>
              <el-button
                style="
                  background-color: var(--el-app-primary);
                  color: white;
                  height: 44px;
                "
                class="z-depth-0 addbtn"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                @click="
                  $router.push({
                    name: 'AddCourseWork',
                    params: {
                      course_work_set_id: $route.params.courseWorkSetId,
                    },
                  })
                "
                >Add Course Work</el-button
              >
            </div>
          </div>
          <hr />
          <div
            v-loading="deleting || (loading && course_works.length > 0)"
            style="width: 100%"
          >
            <data-tables-server
              :data="course_works"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [10, 25, 50] }"
              type="expand"
              :filters="filters"
              :total="totalCourseWorks"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              @query-change="loadData"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getCourseWorks"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Course Work Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Course Work</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{
                    (currentPage - 1) * pageSize + scope.$index + 1
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Course Work Title"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>
                    <strong style="font-weight: 600">Title: </strong>
                    {{ scope.row.title }} </span
                  ><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Time"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span>
                    <strong style="font-weight: 600">Start: </strong>
                    {{ scope.row.available_from }} </span
                  ><br />
                  <span>
                    <strong style="font-weight: 600">End: </strong>
                    {{ scope.row.available_upto }} </span
                  ><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Intakes"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <!-- If the batches are more than one, make the UI clean and show their number and tooltips -->
                  <div v-if="scope.row.course_work_intakes.length == 0">
                    <p
                      class="show-more-intakes"
                      @click="showIntakes(scope.row.course_work_id)"
                    >
                      View Intakes
                    </p>
                  </div>

                  <ul
                    v-else-if="scope.row.course_work_intakes.length <= 5"
                    style="margin: 0px; padding: 0px"
                  >
                    <li
                      v-for="(intake, index) in scope.row.course_work_intakes"
                      :key="index"
                    >
                      {{ intake.course_unit.course_unit_code }} in
                      {{ intake.course_intake_batch.course.course_code }}
                      {{ intake.course_intake_batch.year }}
                      {{ toMonthName(intake.course_intake_batch.month) }}
                      {{ intake.course_intake_batch.cohort }}
                    </li>
                  </ul>
                  <div v-else>
                    {{ scope.row.course_work_intakes.length }} Intakes
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Details"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="auto"
              >
                <template slot-scope="scope">
                  <div class="d-flex justify-content-start">
                    <b style="font-weight: 600">Total Submissions</b>:
                    {{ scope.row.student_course_work_submissions }}
                  </div>
                  <div class="d-flex justify-content-start">
                    <b style="font-weight: 600">Total Marked</b>:
                    {{ scope.row.total_marked_submissions }} /
                    {{ scope.row.student_course_work_submissions }}
                  </div>
                  <div
                    class="d-flex justify-content-start"
                    style="text-align: left"
                  >
                    <b style="font-weight: 600">Students Manual entry</b>:
                    {{
                      scope.row.is_allowed_to_add_students
                        ? "Allowed"
                        : "Disabled"
                    }}
                  </div>
                  <div
                    class="d-flex justify-content-start"
                    style="text-align: left"
                  >
                    <b style="font-weight: 600; padding-right: 5px"
                      >Published:</b
                    >

                    <span
                      style="font-weight: 600; color: green"
                      v-if="scope.row.is_published"
                    >
                      Yes
                    </span>
                    <span v-else style="font-weight: 600; color: orange">
                      No
                    </span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <el-row
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <el-dropdown @command="handleActionsSelection">
                      <span class="el-dropdown-link">
                        <el-button
                          type="primary"
                          icon="el-icon-more"
                          circle
                          style="margin-right: 10px"
                        >
                        </el-button>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          :command="`course-work-details#${scope.row.course_work_id}`"
                        >
                          <i class="el-icon-warning-outline"></i>
                          <span class="drop-down-items">
                            Course Work Details
                          </span>
                        </el-dropdown-item>

                        <el-dropdown-item
                          :disabled="!scope.row.can_edit"
                          :command="`edit-course-work#${scope.row.course_work_id}`"
                        >
                          <i class="el-icon-edit"></i>
                          <span class="drop-down-items">
                            Edit Course Work
                          </span>
                        </el-dropdown-item>

                        <el-dropdown-item
                          :command="`manage-results#${scope.row.course_work_id}`"
                        >
                          <i class="el-icon-tickets"></i>
                          <span class="drop-down-items"> Manage Results </span>
                        </el-dropdown-item>

                        <el-dropdown-item
                          :disabled="!scope.row.can_delete"
                          :command="`delete-course-work#${scope.row.course_work_id}`"
                        >
                          <i class="el-icon-delete"></i>
                          <span class="drop-down-items">
                            Delete Course Work
                          </span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-row>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";

import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../../components/bread-crumb-component";
// import GenerateCourseWorksReport from "../../components/dialogs/generate-course-work-report.vue";

// import { format, parseISO } from "date-fns";
import getMonthName from "../../helpers/getMonthName";
import debounce from "debounce";

export default {
  components: {
    ScaleOut,
    BreadCrumbComponent,
    // GenerateCourseWorkReport
  },
  mixins: [validationMixin],

  data() {
    return {
      loading: false,
      loadingError: false,
      submiting: false,
      deleting: false,

      totalCourseWorks: 0,
      currentPage: 0,
      pageSize: 10,

      notices: [],
      course_works: [],
      course_work: {},

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },
      courseWorkTitle: "",
      filters: [
        {
          value: "",
          search_prop: "flow_no", // define search_prop for backend usage.
        },
      ],
    };
  },

  computed: {
    createdBy() {
      return (creator) => {
        if (!creator) return "N/A";
        return `${creator.first_name} ${creator.last_name}`;
      };
    },

    titleSearch() {
      return this.filters[0].value;
    },
  },

  validations: {},
  created() {},

  mounted() {
    // this.getCourseWorks();
  },

  methods: {
    handleActionsSelection(command) {
      const commandEventFired = command.split("#");
      const commandName = commandEventFired[0];
      const courseWorkId = commandEventFired[1];
      if (commandName === "course-work-details") {
        this.courseWorkDetails(courseWorkId);
      }
      if (commandName === "edit-course-work") {
        this.getCourseWork(courseWorkId);
      }
      if (commandName === "manage-results") {
        this.manageCourseWorkResults(courseWorkId);
      }
      if (commandName === "delete-course-work") {
        this.deleteCourseWork(courseWorkId);
      }
    },
    onSearch: debounce(function () {
      this.getCourseWorks();
    }, 500),
    manageCourseWorkResults(courseWorkId) {
      this.$router.push({
        name: "course-work-results-management",
        params: {
          courseWorkId: courseWorkId,
        },
      });
    },

    async loadData(queryInfo) {
      if (queryInfo) {
        if (
          queryInfo.type == "page" ||
          queryInfo.type == "size" /* ||
          queryInfo.type == "filter" */
        ) {
          this.currentPage = queryInfo.page;
          this.pageSize = queryInfo.pageSize;
          this.getCourseWorks();
        }
      }

      /*   (queryInfo.type === 'page' ||
        queryInfo.type === 'size') &&
        this.$message(`page: ${queryInfo.page}, pageSize: ${queryInfo.pageSize}`) */
    },
    // modulesToolTip(batches) {
    //   return batches
    //     .map(
    //       (batch) =>
    //         `${batch.course.course_code} ${batch.year} ${this.toMonthName(
    //           batch.month
    //         )} ${batch.cohort}`
    //     )
    //     .join(" , ");
    // },

    handleClose(done) {
      this.clearInputs();
      this.$v.$reset();
      done();
    },

    clearInputs() {
      this.title = "";
      this.message = "";
      this.audience = "";
      this.course_intake_batch_ids = [];
      this.valid_upto = "";
    },

    toMonthName(monthNum) {
      return getMonthName(monthNum).toUpperCase();
    },

    async getCourseWorks() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `course-work/fetch-many?page=${this.currentPage}&pageSize=${this.pageSize}&titleSearch=${this.titleSearch}&course_work_set_id=${this.$route.params.courseWorkSetId}`
        );
        if (
          request.data.success &&
          request.data.message == "COURSE_WORKS FETCHED SUCCESSFULLY"
        ) {
          this.course_works = request.data.course_works;
          this.totalCourseWorks = request.data.totalCourseWorks;
          this.courseWorkTitle = request.data.course_work_set.title;
          window.scrollTo(0, 0);
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Course work",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async getCourseWork(course_work_id) {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `course-work/fetch-one/${course_work_id}`
        );
        if (
          request.data.success &&
          request.data.message == "COURSE_WORK FETCHED SUCCESSFULLY"
        ) {
          this.$router.push({
            name: "EditCourseWork",
            params: {
              courseWorkId: course_work_id,
              data: request.data,
            },
          });
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load course work",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    deleteCourseWork(course_work_id) {
      // return; // eslint-disable-line no-unreachable
      this.$confirm(
        "This will permanently delete this course work. Do you want to Continue?",
        "Confirm delete",
        {
          // eslint-disable-line no-unreachable
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.deleting = true;
          this.$http.delete(`course-work/delete-one/${course_work_id}`).then(
            (response) => {
              if (
                response.data.success &&
                response.data.message == "COURSE_WORK DELETED SUCCESSFULLY"
              ) {
                this.getCourseWorks();
                this.showSuccessMessage(
                  "Deleted Successfully",
                  "Course Work deleted successfully!"
                );
                this.deleting = false;
              } else {
                this.showFailedMessage(
                  "Unable to delete",
                  "Unable to delete Course Work"
                );
                this.deleting = false;
              }
            },
            // eslint-disable-next-line no-unused-vars
            (error) => {
              this.deleting = false;
              if (error.response) {
                if (
                  error.response.data.message ===
                  "CANNOT DELETE COURSE WORK AS IT IS ATTACHED TO A MARK SHEET"
                ) {
                  this.showWarningMessage(
                    "Not Permitted",
                    "Unable to delete Course Work as it is attached to a mark sheet"
                  );
                }
                if (
                  error.response.data.message ===
                  "YOU CAN NOT DELETE A COURSE WORK YOU DID NOT CREATE"
                ) {
                  this.showWarningMessage(
                    "Not Allowed",
                    "You can not delete a course work you did not create"
                  );
                }

                return;
              }

              // eslint-disable-line no-unused-vars
              this.showFailedMessage(
                "Unable to delete",
                "Unable to delete Course Work"
              );
            }
          );

          // }).catch(() => {
        })
        .finally(() => {
          this.deleting = false;
        });
    },

    courseWorkDetails(course_work_id) {
      this.$router.push({
        path: `/dashboard/course-work-details/${course_work_id}`,
      });
    },

    async showIntakes(courseWorkId) {
      let request = await this.httpRequest({
        method: "GET",
        url: `course-work/fetch-intakes/${courseWorkId}`,
        loadingPropertyName: "loading",
        errorLoadingPropertyName: "loadingError",
        showSuccessMessage: false,
      });

      if (
        request &&
        request.success &&
        request.message == "Coursework intakes fetched successfully"
      ) {
        this.course_works = this.course_works.map((courseWork) => {
          if (courseWork.course_work_id == request.courseWork.course_work_id) {
            courseWork.course_work_intakes =
              request.courseWork.course_work_intakes;
          }
          return courseWork;
        });
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.results-pending-notice {
  background-color: rgb(253, 235, 230);
  font-weight: 300;
}

.description-text {
  font-size: 14px;
  font-weight: 500;
  height: 24px;
}

#reason-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: small;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .results-pending-notice {
    width: 100% !important;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .results-pending-notice {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .results-pending-notice {
    width: 100% !important;
  }
}
</style>

<style scoped>
* {
  box-sizing: border-box;
}

.drop-down-items {
  cursor: pointer;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.course_work_dialogs.el-dialog {
  width: 70%;
}

.search_by_input {
  width: 400px !important;
}

.course_work_dialogs.el-select .el-input__inner {
  cursor: pointer;
  height: 36px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}
.course_work_dialogs.el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.94em;
  line-height: 23px;
  font-weight: 300;
}

.course_work_dialogs.el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.course_work_dialogs.el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.course_work_dialogs.el-upload-dragger {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 366px !important;
  height: 150px !important;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.course_work_dialogs.el-icon-upload:before {
  font-size: 50px;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .course_work_dialogs.el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .course_work_dialogs.el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .course_work_dialogs.el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .course_work_dialogs.el-dialog {
    width: 90%;
  }
}
</style>
